import React, { useState, useEffect, useRef } from 'react';
import './Home.css';
import { DataStore,Analytics } from 'aws-amplify';
import { Diagnosis, Feedback, GeneratedNotes, NewICD10Request, Icd10RequestStatus, SavedNotes } from '../models';
import DiagnosisInformation from './DiagnosisInformation';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { debounce, unionBy } from 'lodash';
import TextSnippet from './TextSnippet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt, faSignOutAlt, faFileCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button, notification } from 'antd';
import { Auth } from 'aws-amplify';
import { ReactComponent as Logo } from './logo.svg';
import Navbar from './NavBar';
import { CSSTransition } from 'react-transition-group';
import NewDiagnosisModal from './NewDiagnosisModal';
import { faSync } from '@fortawesome/free-solid-svg-icons';

//AsyncSelect custom options styles
const Option = (props) => {
  const { data } = props;
  return (
    <div>
      <components.Option {...props}>
        {data.label}
        {data.verified && <span style={{ color: '#FFA500', fontSize: '1em'  }}> ● </span>}
      </components.Option>
    </div>
  );
};

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [loadingICD10, setLoadingICD10] = useState(false);
  const [generatedNote, setGeneratedNote] = useState('');
  const [selectedDiagnosis, setSelectedDiagnosis] = useState([]);
  const [previousOptions, setPreviousOptions] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [newDiagnosisModalVisible, setNewDiagnosisModalVisible] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [notificationApi, contextHolder] = notification.useNotification();
  const [hasSelectedBefore, setHasSelectedBefore] = useState(false);
  const prevGeneratedNote = useRef(generatedNote);
  const saveNoteTimeout = useRef(null);

  useEffect(() => {
    const getCurrentUserEmail = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        setUserEmail(currentUser.attributes.email);
      } catch (error) {
        console.error(error);
      }
    };

    getCurrentUserEmail();
  }, []);

  useEffect(() => {
    setGeneratedNote("");
    prevGeneratedNote.current = generatedNote
    if (selectedDiagnosis.length !== 1) return;

    const getExistingNote = async () =>{
      const existingNotes = await DataStore.query(
        SavedNotes, 
        (c) => c.and(c => [
          c.code.eq(selectedDiagnosis[0].code),
          c.user.eq(userEmail)
        ])
      );
      if (existingNotes.length > 0) {
        // Update the existing note
       setGeneratedNote(existingNotes[0].note);
      }
    }
    getExistingNote()
  }, [selectedDiagnosis]);

  //save generated note every time it changes
  useEffect(() => {
    if (generatedNote === "") return;
    
    if (prevGeneratedNote.current==="") scrollToButton();
    if (selectedDiagnosis.length !== 1) return;
    if (generatedNote === prevGeneratedNote.current) return;
  
    // Clear the previous timeout if it exists
  if (saveNoteTimeout.current) {
    clearTimeout(saveNoteTimeout.current);
  } 

   // Set a new timeout to save the note after a delay
  saveNoteTimeout.current = setTimeout(async () => {
      // Check if a note with the specified code and user already exists
      const existingNotes = await DataStore.query(
        SavedNotes, 
        (c) => c.and(c => [
          c.code.eq(selectedDiagnosis[0].code),
          c.user.eq(userEmail)
        ])
      );
      if (existingNotes.length > 0) {
        // Update the existing note
        await DataStore.save(
          SavedNotes.copyOf(existingNotes[0], updated => {
            updated.note = generatedNote;
          })
        );
      } else {
        // Create a new note
        await DataStore.save(
          new SavedNotes({
            user: userEmail,
            code: selectedDiagnosis[0].code,
            note: generatedNote
          })
        );
      }
    },1000);
    prevGeneratedNote.current = generatedNote;
  }, [generatedNote]);
  
  const loadOptions = async (inputValue, callback) => {

    const words = inputValue.split(' ').map(word => word.toLowerCase());

    const results = await DataStore.query(
        Diagnosis, 
        (c) => c.or((c) => [
            c.and((c) => words.map(word => c.name.contains(word))),
            c.code.contains(inputValue.toUpperCase())
        ]),
        {
            page: 0,
            limit: 20,
            sort: s => s.code("ASCENDING")
        }
    );
    
    const options = results.map((diagnosis) => ({
      value: diagnosis.id,
      label: `${diagnosis.code} - ${diagnosis.name}`,
      symptoms: diagnosis.symptoms,
      treatments: diagnosis.treatments,
      code: diagnosis.code,
      name: diagnosis.name,
      exams: diagnosis.exams,
      labs: diagnosis.labs,
      differentialDiagnoses: diagnosis.differentialDiagnosis,
      verified: diagnosis.verified
    }));

    setPreviousOptions(options);
    callback(options);
  };

  // Debounce function
  function debounce(func, delay) {
    let debounceTimer;
    return function(...args) {
        const context = this;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  }
    // Debounce loadOptions with a delay of 500ms
  const debouncedLoadOptions = debounce(loadOptions, 500);

  // Helper function to scroll down the page smoothly

  const handleDiagnosisSelect = async (selectedOptions) => {
    try {
      if (selectedOptions && selectedOptions.length > 0) {
        setHasSelectedBefore(true);
      }

      // Analytics record event:
      Analytics.record({
        name: 'diagnosticSearch',
        // Attribute values must be strings
        attributes: { user: userEmail, info: JSON.stringify(selectedOptions.map(op => op.label)) }
      });

      const selectedOptionIds = selectedOptions.map(option => option.value);
      const concatSelectedAndResult = unionBy(previousOptions, selectedDiagnosis, 'value');
      let selected = concatSelectedAndResult.filter(option =>
        selectedOptionIds.includes(option.value)
      );

      setSelectedDiagnosis(selected);
    } catch (error) {
      console.error(error);
    }
  };

  function scrollToButton() {
    return new Promise(resolve => {
      const buttons = document.getElementsByClassName('generate-button');
      const button = buttons[0];
     // const yCoordinate = button.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: button.offsetTop, behavior: 'smooth' });

      // Wait for scrolling to complete
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  }

  const handleGenerateNote = async (infoToGenerateNote) => {
    setGeneratedNote('');
    setLoading(true);

    try {
      // //save generated diagnosis
      const generatedNote = await DataStore.save(
        new GeneratedNotes({
          user: userEmail,
          diagnosis: infoToGenerateNote,
        })
      );

      //subscribe to generated note
      const subscription = DataStore.observe(GeneratedNotes, generatedNote.id).subscribe(msg => {
        if (msg.element.note) {
          switch (msg.element.note) {
            case "ERROR":
              notificationApi.error({
                message: `Failed`,
                description: `Sorry, we are having some issues generating your note. Please try again in few minutes.`,
                placement: 'bottomRight',
                duration: 20,
              });
              break;
            default:
              setGeneratedNote(msg.element.note);
              break;
          }
          subscription.unsubscribe();
          setLoading(false);
        }
      });

    } catch (error) {
      console.error(error);
    }
  };

  const handleFeedbackSubmit = async () => {
    try {
      await DataStore.save(
        new Feedback({
          user: userEmail,
          text: feedbackText
        })
      );
      setFeedbackText('');
      setModalVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log('Error signing out:', error);
    }
  };

  const handleNewDiagnosisSubmit = async (icd10) => {
    try {
      const newICD10 = DataStore.save(
        new NewICD10Request({
          code: icd10.code,
          name: icd10.name,
          user: userEmail,
          status: Icd10RequestStatus.PENDING
        })
      );

      setLoadingICD10(true);

      setNewDiagnosisModalVisible(false);

      await newICD10;

      //subscribe to new ICD10
      const subscription = DataStore.observe(NewICD10Request, newICD10.id).subscribe(async msg => {
        if (msg.element.status === Icd10RequestStatus.SUCCESS) {
          subscription.unsubscribe();
          setLoadingICD10(false);

          await DataStore.clear();
          notificationApi.success({
            message: `Success`,
            description: `"${icd10.code}: ${icd10.name}" has been added and is ready to be used.`,
            placement: 'bottomRight',
            duration: 20,
          });
        }
        if (msg.element.status === Icd10RequestStatus.ERROR && msg.element.log) {
          notificationApi.warning({
            message: `Notice`,
            description: `${msg.element.log}`,
            placement: 'bottomRight',
            duration: 20,
          });
          subscription.unsubscribe();
          setLoadingICD10(false);
        }
        else if (msg.element.status === Icd10RequestStatus.ERROR) {
          notificationApi.error({
            message: `Failed`,
            description: `Sorry We are having some issues. ICD10 code ${icd10.code} addition has failed. Please try again later.`,
            placement: 'bottomRight',
            duration: 20,
          });
          subscription.unsubscribe();
          setLoadingICD10(false);
        }
      });

    } catch (error) {
      console.error(error);
    }
  }

  const nodeRef = useRef(null);
  return (
    <>
      <CSSTransition nodeRef={nodeRef} in={hasSelectedBefore} timeout={500} classNames="fade" unmountOnExit>
        <Navbar />
      </CSSTransition>

      <CSSTransition nodeRef={nodeRef} in={!hasSelectedBefore} timeout={300} classNames="fade" unmountOnExit>
        <div ref={nodeRef}>
          <div className="logo-signout-container" >
            <Button variant="outline-light" className="logo-signout" onClick={handleSignOut}><FontAwesomeIcon icon={faSignOutAlt} /></Button>
          </div>
          <div className='logo-container'>
            <div className='logo'>
              <Logo />
            </div>

            <span className='logo-text'>IntelliNotes</span>
            <span className='logo-slogan'>Diagnosis Made Easy, Notes Made Swift</span>
          </div>
        </div>
      </CSSTransition>

      <div className='container'>
        {contextHolder}
        <div className="search-container">
          <AsyncSelect
            className="async-select"
            value={selectedDiagnosis}
            getOptionLabel={(option) => `${option.code} - ${option.name}`}
            getOptionValue={(option) => option.value}
            defaultOptions={previousOptions}
            loadOptions={debouncedLoadOptions}
            onChange={handleDiagnosisSelect}
            isMulti
            placeholder=""
            noOptionsMessage={({ inputValue }) => !inputValue ? "Search by ICD10 code or diagnosis name ..." : "No results found"}
            components={{ Option }} 
          />
        </div>
        <div className="page-container">

          <CSSTransition nodeRef={nodeRef} in={!!selectedDiagnosis.length} timeout={300} classNames="fade" unmountOnExit>
            <div className="grid-container">
              <div className="info-container">
                <DiagnosisInformation data={selectedDiagnosis} handleGenerateNote={handleGenerateNote} loading={loading} />
              </div>

              {generatedNote && (
                <div className="copy-container">
                  <TextSnippet text={generatedNote} setText={setGeneratedNote} />
                </div>
              )}

            </div>
          </CSSTransition>
          {loadingICD10 ? (
            <div className="newdiagnosis-icon">
              <FontAwesomeIcon className="newdiagnosis-icon" icon={faSync} spin size="1x" />
            </div>
          ) : (
            <FontAwesomeIcon icon={faFileCirclePlus} className="newdiagnosis-icon" onClick={() => setNewDiagnosisModalVisible(true)} title="Add ICD10" />
          )
          }
          <FontAwesomeIcon icon={faCommentAlt} className="feedback-icon" onClick={() => setModalVisible(true)} title="Leave feedback" />

          <Modal
            title="Feedback"
            open={modalVisible}
            onCancel={() => setModalVisible(false)}
            footer={[
              <Button key="cancel" onClick={() => setModalVisible(false)}>Cancel</Button>,
              <Button key="submit" type="primary" onClick={handleFeedbackSubmit} disabled={!feedbackText}>Submit</Button>
            ]}
          >
            <textarea
              value={feedbackText}
              onChange={event => setFeedbackText(event.target.value)}
              placeholder="Compliment or critique, we are just happy to hear from you :)"
              style={{ width: '100%', height: 500 }}
            />
          </Modal>
          <NewDiagnosisModal
            visible={newDiagnosisModalVisible}
            onCancel={() => setNewDiagnosisModalVisible(false)}
            onSubmit={handleNewDiagnosisSubmit}
          />
        </div>
      </div>
    </>
  );
};

export default Home;
