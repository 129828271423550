import { Image, useTheme } from "@aws-amplify/ui-react";
import { ReactComponent as Logo } from './../logo.svg';
import './header.css';


export function Header() {
  const { tokens } = useTheme();

  return (
    <div className='login-logo-container'>
      <span className='login-logo'>
        <Logo />
      </span>

      <span className='login-logo-text'>IntelliNotes</span>
    </div>
  );
}
