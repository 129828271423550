import { Auth } from 'aws-amplify';

const getUserEmail = async() => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const email = user.attributes.email;
    return email;
  } catch (error) {
    console.log('Error getting user email: ', error);
  }
}

export default getUserEmail