/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "ce78f1571ac54445b048a8c49dd8f45d",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "ce78f1571ac54445b048a8c49dd8f45d",
            "region": "us-east-1"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://vyjsneoe6vfxbooxatvsjxhy4y.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-tzzja7qwbfcqpmyp2ody42usxy",
    "aws_cloud_logic_custom": [
        {
            "name": "api28660770",
            "endpoint": "https://dky2n3hfvc.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:62b600fd-81ab-44de-bd9b-14ecab2bf88d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_zguvrBYTT",
    "aws_user_pools_web_client_id": "3nn60lcgvkn3vnorkq2a67cb9u",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "predictions": {
        "convert": {
            "transcription": {
                "region": "us-east-1",
                "proxy": false,
                "defaults": {
                    "language": "en-US"
                }
            },
            "translateText": {
                "region": "us-east-1",
                "proxy": false,
                "defaults": {
                    "sourceLanguage": "es",
                    "targetLanguage": "en"
                }
            }
        }
    }
};


export default awsmobile;
