import { Flex, Link, useAuthenticator, useTheme } from "@aws-amplify/ui-react";
import { loadStripe } from "@stripe/stripe-js";

export function SignInFooter() {
  const { toResetPassword } = useAuthenticator();
  const { tokens } = useTheme();

  //to test locally
  // async function handleClick() {
  //   const stripe = await loadStripe(
  //     "pk_test_51NJSq0F2i66Y3FMBDZBLwQzSsIbBQcmQSIWiaUVFmvrQHzjT3Y82AWujXiHLVhaDHwTlZrgv0aLxX9CDUT0S9C0300sTzMlUcp"
  //   );
  //   const { error } = await stripe.redirectToCheckout({
  //     lineItems: [{ price: "price_1NJT6iF2i66Y3FMByfExAoKQ", quantity: 1 }],
  //     mode: "subscription",
  //     successUrl: "http://localhost:3000/",
  //     cancelUrl: "http://localhost:3000/",
  //   });
  // }

  async function handleClick() {
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
    const { error } = await stripe.redirectToCheckout({
      lineItems: [{ price: process.env.REACT_APP_PRICE_ID, quantity: 1 }],
      mode: "subscription",
      successUrl: process.env.REACT_APP_SUCCESS_URL,
      cancelUrl: process.env.REACT_APP_SUCCESS_URL,
    });
  }

  return (
    <Flex justifyContent="center" padding={`0 0 ${tokens.space.medium}`}>
      <Link onClick={toResetPassword}>Reset your password</Link>
      <Link onClick={handleClick}>Create Account</Link>
    </Flex>
  );
}
