// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Icd10RequestStatus = {
  "PENDING": "PENDING",
  "SUCCESS": "SUCCESS",
  "ERROR": "ERROR"
};

const { NewICD10Request, GeneratedNotes, SavedNotes, Diagnosis, Feedback, User } = initSchema(schema);

export {
  NewICD10Request,
  GeneratedNotes,
  SavedNotes,
  Diagnosis,
  Feedback,
  User,
  Icd10RequestStatus
};