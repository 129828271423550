import React from 'react';
import { Button } from 'react-bootstrap';
import './NavBar.css';
import { Auth } from 'aws-amplify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileSignature, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as Logo } from './logo.svg';


const Navbar = () => {
  const handleSignOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log('Error signing out:', error);
    }
  };
  return (
    <nav className="navbar" style={{ backgroundColor: '#047D95' }}>
      <div className="navbar-logo">
        {/* <FontAwesomeIcon icon={faFileSignature} size="2x" color='white' />
        ntelli Notes */}
        <Logo />
      </div>
      <Button variant="outline-light" className="navbar-signout" onClick={handleSignOut}><FontAwesomeIcon icon={faSignOutAlt} /></Button>
    </nav>
  );
};

export default Navbar;
