import React, { useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import Button from 'antd/lib/button/button';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import { DataStore } from 'aws-amplify';
import { Diagnosis } from '../models';


const NewDiagnosisModal = ({ visible, onCancel, onSubmit }) => {
  const [icd10, setICD10] = useState({});
  const [previousOptions, setPreviousOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const loadOptions = async (inputValue, callback) => {
    const response = await fetch(
      `https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&terms=${inputValue}`
    );
    const data = await response.json();
    const options = data[3].map((item) => ({
      value: item[0],
      label: `${item[0]} - ${item[1]}`,
      code: item[0],
      name: item[1]
    }));
    setPreviousOptions(options);

    callback(options);
  };

  const debouncedLoadOptions = debounce(loadOptions, 500);

  const handleSubmit = async () => {
    try {
      await onSubmit(icd10);
      setICD10({});
      setErrorMessage('');
    }
    catch (e) {
      console.error(`error submitting: ${e}`);
    }
  };

  const handleICD10 = () => {
    const url = 'https://www.icd10data.com/';
    window.open(url, '_blank');
  };

  const onCancelModal = () => {
    setICD10({});
    setErrorMessage('');
    onCancel();
  }
  const handleICD10Select = async (selectedOption) => {
    if (!selectedOption) return;

    setErrorMessage('');

    //check if icd10 code exist
    const result = await DataStore.query(Diagnosis, (diagnosis) =>
      diagnosis.code.eq(selectedOption.code), {
      page: 0,
      limit: 5
    }
    );

    if (result.length) setErrorMessage('ICD10 code already exist.');

    setICD10(selectedOption);
  };

  return (
    <Modal
      title="Add new diagnosis"
      open={visible}
      onCancel={onCancelModal}
      footer={[
        // <Button key="icd10" onClick={handleICD10}>ICD10</Button>,
        <Button key="cancel" onClick={onCancelModal}>Cancel</Button>,
        <Button key="submit" type="primary" disabled={errorMessage !== ''} onClick={handleSubmit}>Submit</Button>
      ]}
    >
      <AsyncSelect
        className="async-select-icd10"
        value={icd10}
        defaultOptions={previousOptions}
        loadOptions={debouncedLoadOptions}
        onChange={handleICD10Select}
        placeholder={"Code or name ..."}
        noOptionsMessage={({ inputValue }) => !inputValue ? "Search by ICD10 code or diagnosis name ..." : "No results found"}
      />
      {errorMessage && (<span style={{ "color": "red", "fontSize": "12px" }}>{errorMessage}</span>)
      }
    </Modal>
  );
};

export default NewDiagnosisModal;
