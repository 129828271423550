
import Home from './components/Home';
import { withAuthenticator } from '@aws-amplify/ui-react'
import { Amplify, DataStore, Hub, Analytics, Auth } from 'aws-amplify';
import { Header } from './components/login/header';
import { SignInFooter } from './components/login/signInFooter';
import { Footer } from './components/login/footer';
import Disclaimer  from './components/Disclaimer';
import React, { useEffect, useState } from 'react';
import { User } from 'models';
import * as process from 'process';
import { AmazonAIPredictionsProvider } from '@aws-amplify/predictions';

Amplify.addPluggable(new AmazonAIPredictionsProvider());

//pollyfills needed for buffer and process on microphone-stream
window['process'] = process;
window.Buffer = window.Buffer || require('buffer').Buffer;

function App() {
  const [showDisclaimer, setShowDisclaimer] = useState(false);
 
  useEffect(() => {
    const startSync = async () => 
      await DataStore.start();
      Hub.listen('datastore', (data) => {
        if (data.payload.event === 'ready') {
          console.log('Local store is synced with the cloud');
          checkUser();
        }
      });
      startSync();
      }, []);
  
  Hub.listen('auth', async (data) => {
    if (data.payload.event === 'signOut') {
      //In case multiple users share the same device and your schema defines user-specific data, make sure you call Amplify.DataStore.clear() when switching users. 
      //https://docs.amplify.aws/lib/datastore/sync/q/platform/js/#clear-local-data
      await DataStore.clear();
      Analytics.record({ name: 'userSignOut' });
    }
  });
  
  async function checkUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const userEmail = user.attributes.email;
      const existingUser = await DataStore.query(User, (c) => c.user.eq(userEmail));
      if (existingUser.length === 0 || !existingUser[0].agreeDisclaimer) {
        setShowDisclaimer(true);
      }
    } catch (error) {
      console.log('Error checking user: ', error);
    }
  }

  return (
    <>
     {showDisclaimer ? <Disclaimer setShowDisclaimer={setShowDisclaimer} /> : <Home />}
    </>
  );
}

export default withAuthenticator(App, {
  loginMechanisms: ['email'],
  components: {
    Header,
    SignIn: {
      Footer: SignInFooter
    },
    //Footer
  },
  hideSignUp: true,
});
