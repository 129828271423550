import React, { useState, useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faMicrophone, faRecycle, faSync } from '@fortawesome/free-solid-svg-icons';
import './TextSnippet.css';
import 'react-quill/dist/quill.snow.css';
import mic from 'microphone-stream';
import { Predictions } from 'aws-amplify';


const TextSnippet = ({ text, setText }) => {
  const [copied, setCopied] = useState(false);
  const [converting, setConverting] = useState(false);
  const reactQuillRef = useRef(null);
  
  const insertTextAtCursor = (text) => {
    const quill = reactQuillRef.current.getEditor();
    const range = quill.getSelection();
    let position = range ? range.index : quill.getLength();
    quill.insertText(position, text);
    quill.setSelection(position + text.length);
  }
  

  const handleCopyClick = () => {
    const quillEditor = document.querySelector('.ql-editor');
    const plainText = quillEditor.innerText || '';
    let cleanedText = plainText.replace(/\n{2,}/g, '\n');

    navigator.clipboard.writeText(cleanedText);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  const AudioRecorder = (props) => {
    const [recording, setRecording] = useState(false);
    const [micStream, setMicStream] = useState();
    const [selectedLanguage, setSelectedLanguage] = useState(() => {
      // Initialize state from local storage or default to 'en-US'
      let lang = localStorage.getItem('userLanguageSelection');
      return lang || 'en-US';
    });
    const [audioBuffer] = useState(
      (function () {
        let buffer = [];
        function add(raw) {
          buffer = buffer.concat(...raw);
          return buffer;
        }
        function newBuffer() {
          console.log("resetting buffer");
          buffer = [];
        }

        return {
          reset: function () {
            newBuffer();
          },
          addData: function (raw) {
            return add(raw);
          },
          getData: function () {
            return buffer;
          }
        };
      })()
    );

    useEffect(() => {
      let timeoutId; // Declare a variable to store the timeout ID

    // Stop recording after 1 minute
    if (micStream) {
        timeoutId = setTimeout(() => {
            stopRecording.call(this);
        }, 60000);
    }

    // Cleanup function
    return () => {
        if (timeoutId) {
            clearTimeout(timeoutId); // Clear the timeout
        }
    };
    },[micStream])
   
    useEffect(() => {
      // Store the user's language selection in local storage whenever it changes
      localStorage.setItem('userLanguageSelection', selectedLanguage);
  }, [selectedLanguage]);

    const stopRecording = async () => {
      console.log('stop recording');
      const { finishRecording } = props;

      micStream.stop();
      setMicStream(null);
      setRecording(false);

      const resultBuffer = audioBuffer.getData();

      if (typeof finishRecording === "function") {
        finishRecording(resultBuffer, selectedLanguage);
      }
    }

    const startRecording= async () => {
      console.log('start recording');
      audioBuffer.reset();

      window.navigator.mediaDevices.getUserMedia({ video: false, audio: true }).then((stream) => {
        const startMic = new mic();

        startMic.setStream(stream);
        startMic.on('data', (chunk) => {
          var raw = mic.toRaw(chunk);
          if (raw == null) {
            return;
          }
          audioBuffer.addData(raw);

        });

        setRecording(true);
        setMicStream(startMic);
   
      });
    }

    return (
      <>
       <>
        <select 
          className="dropdown-button" 
          value={selectedLanguage}  // Ensure the dropdown's value is bound to the state
          onChange={(e) => setSelectedLanguage(e.target.value)}
        >
          <option value="en-US">en</option>
          <option value="es-US">es</option>
        </select>
          </>

       {!converting && !recording && 
         
            <button className="record-button" onClick={() =>startRecording()}>
                <FontAwesomeIcon icon={faMicrophone} size="1x" title="Start recording"/>
            </button>
          }

          {!converting && recording && 
            <button className="record-button" onClick={() => stopRecording()}>
                <FontAwesomeIcon icon={faMicrophone} fade size="1x" title="Stop recording"/>
            </button>
            
          }
          
          {converting && 
            <button  className="record-button">
              <FontAwesomeIcon className="record-button-sync" icon={faSync} spin size="1x" title="Converting audio to text"/>
            </button>
          }
      </>
    );
  }

  const convertFromBuffer = (bytes, selectedLanguage) => {

    setConverting(true);

    Predictions.convert({
        transcription: {
            source: {
                bytes
            },
            language: selectedLanguage, // Use the language selected by the user
        },
    }).then(async ({ transcription: { fullText } }) => {
        setConverting(false);

        let finalText = fullText;

        // If the user selected Spanish, translate the transcribed text to English
        if (selectedLanguage === 'es-US') {
            try {
                const translatedText = await Predictions.convert({
                    translateText: {
                        source: {
                            text: fullText,
                            language: "es" // Spanish
                        },
                        targetLanguage: "en" // English
                    }
                });

                finalText = translatedText.text;
            } catch (translationError) {
                console.error("Translation error:", translationError);
            }
        }

        insertTextAtCursor(finalText);
    })
    .catch(err => {
        setConverting(false);
        console.log(JSON.stringify(err, null, 2))
    });
  }

  const breakText = text.replace(/\n/g, '<br/>');
  const htmlText = breakText.replace(/SUBJECTIVE:/g, '<strong>SUBJECTIVE:</strong>')
    .replace(/OBJECTIVE:/g, '<strong>OBJECTIVE:</strong>')
    .replace(/ASSESSMENT:/g, '<strong>ASSESSMENT:</strong>')
    .replace(/PLAN:/g, '<strong>PLAN:</strong>')
    .replace(/General:/g, '<strong>General:</strong>')
    .replace(/Cardiovascular:/g, '<strong>Cardiovascular:</strong>')
    .replace(/Respiratory:/g, '<strong>Respiratory:</strong>')
    .replace(/Gastrointestinal:/g, '<strong>Gastrointestinal:</strong>')
    .replace(/Neurological:/g, '<strong>Neurological:</strong>')
    ;

  return (
    <div className="code-snippet">
      <ReactQuill
        ref={reactQuillRef}
        className="code-editor"
        value={htmlText}
        onChange={setText}
        modules={{
          toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }]
          ],
          clipboard: {
            matchVisual: false,
          },
        }}
        style={{ whiteSpace: 'pre-wrap' }}
      />
      <button className="copy-button" onClick={handleCopyClick}>
        <FontAwesomeIcon icon={faCopy} />
        <span>{copied ? 'Copied!' : 'Copy'}</span>
      </button> 
      <AudioRecorder finishRecording={convertFromBuffer} />
    </div>
  );
};

export default TextSnippet;


