import React, { useState, useEffect } from 'react';
import './DiagnosisInformation.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faMinus } from '@fortawesome/free-solid-svg-icons';

const DiagnosisInformation = ({ data, handleGenerateNote, loading }) => {

  const [diagnoses, setDiagnoses] = useState([]);

  useEffect(() => {

    initDiagnoses();

    function initDiagnoses() {

      setDiagnoses(data.map(d => ({
        diagnosis: d.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
        symptoms: d.symptoms || [],
        treatments: d.treatments || [],
        differentialDiagnosis: d.differentialDiagnoses,
        exams: d.exams || [],
        labs: d.labs || [],
        code: d.code || "",
        verified: d.verified
      })));
    }
  }, [data]);

  useEffect(() => {
    if (!loading) {
      document.documentElement.scrollTop = document.body.scrollTop = document.documentElement.scrollHeight;
    }
  }, [loading]);


  const handleSymptomRemove = (diagIndex, symptomsIndex) => {
    setDiagnoses(diagnoses => diagnoses.map((diag, i) => i === diagIndex ? {
      ...diag,
      symptoms: diag.symptoms.filter((_, j) => j !== symptomsIndex)
    } : diag));
  };

  const handleDifferentialDiagnosisRemove = (diagIndex, differentialDiagnosisIndex) => {
    setDiagnoses(diagnoses => diagnoses.map((diag, i) => i === diagIndex ? {
      ...diag,
      differentialDiagnosis: diag.differentialDiagnosis.filter((_, j) => j !== differentialDiagnosisIndex)
    } : diag));
  };


  const handleDDRemove = (diagIndex, ddIndex, symptomIndex) => {

    setDiagnoses(diagnoses => diagnoses.map((diag, i) => i === diagIndex ? {
      ...diag,
      differentialDiagnosis: diag.differentialDiagnosis.map((dd, i) => {
        if (i === ddIndex) {
          return {
            ...dd,
            symptoms: dd.symptoms.filter((_, j) => j !== symptomIndex),
          };
        }
        return dd;
      })
    } : diag));

  };

  const handleExamRemove = (diagIndex, examIndex) => {
    setDiagnoses(diagnoses => diagnoses.map((diag, i) => i === diagIndex ? {
      ...diag,
      exams: diag.exams.filter((_, j) => j !== examIndex)
    } : diag));
  };

  const handleLabRemove = (diagIndex, labIndex) => {
    setDiagnoses(diagnoses => diagnoses.map((diag, i) => i === diagIndex ? {
      ...diag,
      labs: diag.labs.filter((_, j) => j !== labIndex)
    } : diag));
  };

  const handleTreatmentRemove = (diagIndex, treatmentIndex) => {
    setDiagnoses(diagnoses => diagnoses.map((diag, i) => i === diagIndex ? {
      ...diag,
      treatments: diag.treatments.filter((_, j) => j !== treatmentIndex)
    } : diag));
  };

  const generateNote = () => {
    if (!diagnoses.length) return;

    if (diagnoses.length === 1) {
      return handleGenerateNote(diagnoses);
    }

    handleGenerateNote(diagnoses.map(d => ({
      diagnosis: d.diagnosis,
      symptoms: d.symptoms || [],
      treatments: d.treatments || [],
      code: d.code || "",
    })
    ));

  };

  return (
    <div>
      {diagnoses.map((diag, diagIndex) => (
        <div key={diagIndex}>
          <h2>{diag.code} - {diag.diagnosis} {diag.verified && <span style={{ color: '#FFA500', fontSize: '1em'  }}> ● </span>}</h2>

          <h3>Symptoms:</h3>
          <ul>
            {diag.symptoms.map((symptom, index) => (
              <li key={index}>
                {symptom}{' '}
                <span
                  className="remove-button" onClick={() => handleSymptomRemove(diagIndex, index)}> {'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}<FontAwesomeIcon icon={faMinus} /></span>
              </li>
            ))}
          </ul>
          <>
            {diagnoses.length === 1 && (
              <>
                <h3>Differential Diagnosis:</h3>
                <ul>
                  {diag.differentialDiagnosis && Array.from(diag.differentialDiagnosis).map((dd, ddIndex) => (
                    <li key={ddIndex}>
                      <h4>Diagnosis: {dd.name}
                        <span className="remove-button" onClick={() => handleDifferentialDiagnosisRemove(diagIndex, ddIndex)}>{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}<FontAwesomeIcon icon={faMinus} /></span></h4>
                      <p>Symptoms:</p>
                      <ul>
                        {dd.symptoms.map((symptom, symptomIndex) => (
                          <li key={symptomIndex}>
                            {symptom}{' '}
                            <span
                              className="remove-button"
                              onClick={() => handleDDRemove(diagIndex, ddIndex, symptomIndex)}
                            >
                              {'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}<FontAwesomeIcon icon={faMinus} />
                            </span>
                          </li>
                        ))}
                      </ul>

                    </li>
                  ))}
                </ul>

                <h3>Exams:</h3>
                <ul>
                  {diag.exams.map((exam, index) => (
                    <li key={index}>
                      {exam} <span
                        className="remove-button"
                        onClick={() => handleExamRemove(diagIndex, index)}>{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'} <FontAwesomeIcon icon={faMinus} /></span>
                    </li>
                  ))}
                </ul>

                <h3>Labs:</h3>
                <ul>
                  {diag.labs.map((lab, index) => (
                    <li key={index}>
                      {lab} <span
                        className="remove-button"
                        onClick={() => handleLabRemove(diagIndex, index)}>{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'} <FontAwesomeIcon icon={faMinus} /></span>
                    </li>
                  ))}
                </ul>
              </>
            )
            }
          </>
          <h3>Treatments:</h3>
          <ul>
            {diag.treatments.map((treatment, index) => (
              <li key={index}>
                {treatment}{' '}
                <span
                  className="remove-button"
                  onClick={() => handleTreatmentRemove(diagIndex, index)}>{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'} <FontAwesomeIcon icon={faMinus} /></span>
              </li>
            ))}
          </ul>
        </div >
      ))}
      {loading ? (
        <div className="thinking">
          {'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'} <FontAwesomeIcon className="generate-loading" icon={faPencil} shake size="3x" />
        </div>
      ) : (
        <button className="generate-button" onClick={() => generateNote()}>Generate New Note</button>
      )
      }
    </div >

  );
};

export default DiagnosisInformation;
