import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import { Auth, DataStore } from 'aws-amplify';
import { User } from '../models';
import getUserEmail from 'utils/functions';



const Disclaimer = ({ setShowDisclaimer }) => {
  const [visible, setVisible] = useState(true);


  const handleOk = async () => {
    const userEmail = await getUserEmail();
    //call backend
    const existingUser = await DataStore.query( User, (c) => c.user.eq(userEmail) );
      if (existingUser.length > 0) {
        // Update the existing user
        await DataStore.save(
          User.copyOf(existingUser[0], updated => {
            updated.agreeDisclaimer = true;
          })
        );
      } else {
        // Create a new user
        await DataStore.save(
          new User({
            user: userEmail,
            agreeDisclaimer : true
          })
        );
    }
    setShowDisclaimer(false);
}

  return (
    <Modal
      title="Disclaimer"
      visible={visible}
      onOk={handleOk}
      closable={false}
      maskClosable={false}
      onCancel={handleOk}
      footer={[
        <Button key="submit" type="primary" onClick={handleOk}>
          I Agree
        </Button>,
      ]}
    >
      <div style={{ maxHeight: '200px', overflowY: 'auto' ,fontSize:'11px'}}>
      <p>IntelliNotes is a software tool that aims to facilitate the process of creating medical notes for healthcare providers, but it does not provide any definitive or authoritative medical guidance, instruction, or consultation. The information and recommendations generated by this application are based on general medical principles and standards and do not take into account the individual needs, preferences, conditions, or situations of each patient or site visitor. The application does not create or imply a doctor-patient relationship between the user and the application or any of its creators, owners, developers, or other parties involved in its creation or operation (collectively, “Application Parties”). The application does not replace or override the clinical judgement, expertise, or responsibility of the healthcare provider who uses it. Users of this application should always seek the advice and opinion of other relevant health professionals and refer to authoritative and up-to-date sources of medical information when making clinical decisions.</p>
      
      <p>The information generated by this application is not comprehensive, exhaustive, or conclusive and does not cover all aspects, dimensions, or implications of medical care. It may contain errors, omissions, inaccuracies, inconsistencies, or outdated information that may affect its validity, reliability, or applicability. Users should exercise caution and discretion when using this information and verify its accuracy, completeness, relevance, and timeliness for their purposes. Users should not rely solely on this information and should always consider other factors and sources of information when making clinical decisions.</p>

      <p>Application Parties disclaim any and all liability for any loss, damage, injury, harm, or adverse outcome that may arise from the use of this application or the information provided by it, whether directly or indirectly, intentionally or unintentionally, foreseeably or unforeseeably. Application Parties do not warrant or guarantee the quality, reliability, suitability, availability, security, performance, or functionality of the application or the information. The application and the information are provided “as is” and “as available”, without any representations, warranties, conditions, endorsements, or guarantees of any kind, either express or implied.</p>

      <p>Application Parties are not responsible for any claims, demands, actions, proceedings, suits, judgments, orders, decrees, settlements, awards, damages, costs, expenses (including reasonable legal fees), penalties, fines, sanctions, liabilities, or losses that may be brought by any third party against them or their affiliates, partners, representatives, agents, licensors, contractors, subcontractors, suppliers, distributors, resellers, or customers in connection with the use of the application or the information or any violation of these terms. Users agree to indemnify and hold harmless Application Parties from any and all liabilities, costs, expenses (including reasonable legal fees), damages, and losses that may result from their use of the application or the information or their breach of these terms.</p>
      </div>
    </Modal>
  );
}

export default Disclaimer
